
function loadScript(src) {
    return new Promise(function(resolve, reject) {
        let el = document.createElement("script");
        el.src = src;
        el.onload = function() { resolve(); };
        el.onerror = function() { reject('Error loading script'); };
        document.body.appendChild(el);
    });
}

function allocateArray(strOrArr) {
    let arr = strOrArr instanceof Uint8Array || strOrArr instanceof Array ? strOrArr
        : global.Module.intArrayFromString(strOrArr);
    return global.Module.allocate(arr, 'i8', global.Module.ALLOC_NORMAL);
}

function load() {
    if (global.Module !== undefined) {
        return;
    }
    const mem = 64 * 1024;
    const KB = 1024 * 1024;
    const MB = 1024 * KB;
    const GB = 1024 * MB;
    const WASM_PAGE_SIZE = 64 * 1024;

    const totalMemory = (2*GB - 64*KB) / 1024 / WASM_PAGE_SIZE;
    const initialMemory = Math.min(Math.max(Math.ceil(mem * 1024 / WASM_PAGE_SIZE), 256) + 256, totalMemory);

    if (typeof WebAssembly !== "object" || typeof WebAssembly.instantiate !== "function") {
        global.Module = {
            TOTAL_MEMORY: initialMemory * WASM_PAGE_SIZE
        };
        loadScript('/argon2/argon2-asm.min.js');
        loadScript('/argon2/argon2.js');
        return;
    }

    const wasmMemory = new WebAssembly.Memory({
        initial: initialMemory,
        maximum: totalMemory
    });
    global.Module = {
        wasmBinary: null,
        wasmMemory: wasmMemory,
        buffer: wasmMemory.buffer,
        TOTAL_MEMORY: initialMemory * WASM_PAGE_SIZE
    };

    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/argon2/argon2.wasm', true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function() {
        global.Module.wasmBinary = xhr.response;
        loadScript('/argon2/argon2.js');
    };
    xhr.send(null);
}

load();

export function argon2(password, salt) {
    let tCost = 3;
    let mCost = 64 * 1024;
    let parallelism = 1;
    let pwd = allocateArray(password);
    let pwdlen = password.length;
    let slt = allocateArray(salt);
    let sltlen = salt.length;
    let hash = global.Module.allocate(new Array(32), 'i8', global.Module.ALLOC_NORMAL);
    let hashlen = 32;
    let encoded = global.Module.allocate(new Array(512), 'i8', global.Module.ALLOC_NORMAL);
    let encodedlen = 512;
    let argon2Type = 2;
    let version = 0x13;
    let err;
    let res;
    try {
        res = global.Module._argon2_hash(tCost, mCost, parallelism, pwd, pwdlen, slt, sltlen, hash, hashlen, encoded, encodedlen, argon2Type, version);
    } catch (e) {
        err = e;
    }
    let result;
    if (res === 0 && !err) {
        let hashStr = '';
        let hashArr = new Uint8Array(hashlen);
        for (let i = 0; i < hashlen; i++) {
            let byte = global.Module.HEAP8[hash + i];
            hashArr[i] = byte;
            hashStr += ('0' + (0xFF & byte).toString(16)).slice(-2);
        }
        let encodedStr = global.Module.Pointer_stringify(encoded);
        result = { hash: hashArr, hashHex: hashStr, encoded: encodedStr };
    } else {
        try {
            if (!err) {
                err = global.Module.Pointer_stringify(global.Module._argon2_error_message(res))
            }
        } catch (e) {
        }
        result = { message: err, code: res };
    }
    try {
        global.Module._free(pwd);
        global.Module._free(slt);
        global.Module._free(hash);
        global.Module._free(encoded);
    } catch (e) { }
    if (err) {
        throw result;
    } else {
        return result;
    }
}