import {sha3_256} from 'js-sha3'
import aesjs from 'aes-js'
import {argon2} from './argon2-wrapper.js'
import $ from 'jquery'
import React from 'react';
import './App.css';
import PropTypes from 'prop-types';
import 'open-iconic/font/css/open-iconic-bootstrap.css'
import generateRandomPassword from './password-generator'

function hex2bytes(hex) {
    let result = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
        result[i / 2] = parseInt(hex.substr(i, 2), 16);
    }
    return result;
}

function int2bytes(number) {
    let result = new Uint8Array(4);
    result[0] = number & 0xff;
    result[1] = (number >> 8) & 0xff;
    result[2] = (number >> 16) & 0xff;
    result[3] = (number >> 24) & 0xff;
    return result;
}

function bytes2int(bytes) {
    return bytes[0] + (bytes[1] << 8) + (bytes[2] << 16) + (bytes[3] << 24);
}

function base64toarray(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

const salt = 'e949382bb2b6d22fa70330466ffb9e1363dc07759d81f93403b7cce925c9eea7';
const MAX_PAYLOAD_SIZE = 64 * 1024;

function formatVaultNumber(vaultNumber) {
    let vaultNumberStr = '';
    for (let i = 0; i < 6; i++) {
        vaultNumberStr += vaultNumber[i];
    }
    return vaultNumberStr;
}

function computeKey(vaultNumber, password) {
    let hash = sha3_256.create();
    hash.update(salt);
    hash.update(formatVaultNumber(vaultNumber));
    let vaultSalt = hash.hex();
    return argon2(password, vaultSalt);
}

function computeServicePassword(masterKey) {
    let hash = sha3_256.create();
    hash.update(salt);
    hash.update(hex2bytes('6601'));
    hash.update(masterKey.hash);
    return hash.hex();
}

function computeEncryptionKey(masterKey) {
    let hash = sha3_256.create();
    hash.update(salt);
    hash.update(hex2bytes('6602'));
    hash.update(masterKey.hash);
    return hash.digest();
}

function computeInitialVector(masterKey) {
    let hash = sha3_256.create();
    hash.update(salt);
    hash.update(hex2bytes('6603'));
    hash.update(masterKey.hash);
    return hash.digest().slice(0, 16);
}

async function get(key) {
    return $.ajax({
        type: 'GET',
        url: 'https://pidngt2c52.execute-api.eu-west-2.amazonaws.com/default/' + key,
    });
}

async function del(key) {
    return $.ajax({
        type: 'DELETE',
        url: 'https://pidngt2c52.execute-api.eu-west-2.amazonaws.com/default/' + key,
    });
}

async function put(key, request) {
    return $.ajax({
        type: 'PUT',
        url: 'https://pidngt2c52.execute-api.eu-west-2.amazonaws.com/default/' + key,
        contentType: 'application/json',
        data: JSON.stringify(request),
        dataType: "text",
    });
}

async function decrypt(masterKey) {
    let servicePassword = computeServicePassword(masterKey);
    let result;
    try {
        result = await get(servicePassword);
    } catch (e) {
        throw new Error('Incorrect Password');
    }
    let encryptionKey = computeEncryptionKey(masterKey);
    let iv = computeInitialVector(masterKey);

    let encryptedBytes = base64toarray(result.encryptedVault);
    let aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, iv);
    let decryptedBytes = aesCbc.decrypt(encryptedBytes);
    let version = bytes2int(decryptedBytes.subarray(0, 4));
    if (version === 1) {
        let type = bytes2int(decryptedBytes.subarray(4, 8));
        if (type !== 1) {
            throw new Error('Unknown type: ' + type);
        }
        let len = bytes2int(decryptedBytes.subarray(8, 12));
        let vault = aesjs.utils.utf8.fromBytes(decryptedBytes.subarray(12, 12 + len));
        return {versions: [vault]};
    }
    if (version === 2) {
        let type = bytes2int(decryptedBytes.subarray(4, 8));
        if (type !== 1) {
            throw new Error('Unknown type: ' + type);
        }
        let versionCount = bytes2int(decryptedBytes.subarray(8, 12));
        let versions = [];
        let index = 12;
        for (let i = 0; i < versionCount; i++) {
            let len = bytes2int(decryptedBytes.subarray(index, index + 4));
            let vault = aesjs.utils.utf8.fromBytes(decryptedBytes.subarray(index + 4, index + 4 + len));
            versions.push(vault);
            index += 4 + len;
        }
        return {versions}
    }
    throw new Error('Unknown version: ' + version);


}

function payloadSize(payload) {
    let len = 12;
    for (let i = 0; i < payload.versions.length; i++) {
        len += 4 + payload.versions[i].length;
    }
    return len + (16 - len % 16);
}

async function encrypt(masterKey, payload) {
    let servicePassword = computeServicePassword(masterKey);
    let encryptionKey = computeEncryptionKey(masterKey);
    let iv = computeInitialVector(masterKey);

    // Check if the final vault is too large
    if (payload.versions.length > 0 && payload.versions[payload.versions.length - 1].length > MAX_PAYLOAD_SIZE) {
        throw new Error("Vault too large");
    }

    // Remove duplicate versions
    for (let i = 1; i < payload.versions.length; i++) {
        if (payload.versions[i] === payload.versions[i - 1]) {
            payload.versions.splice(i, 1);
        }
    }

    // Remove versions if they do not fit
    let len = payloadSize(payload);
    while (len > MAX_PAYLOAD_SIZE) {
        payload.versions.shift();
        len = payloadSize(payload);
    }

    let plaintext = new Uint8Array(len);
    plaintext.set(int2bytes(2), 0);
    plaintext.set(int2bytes(1), 4);
    plaintext.set(int2bytes(payload.versions.length), 8);
    let index = 12;
    for (let i = 0; i < payload.versions.length; i++) {
        plaintext.set(int2bytes(payload.versions[i].length), index);
        plaintext.set(aesjs.utils.utf8.toBytes(payload.versions[i]), index + 4);
        index += 4 + payload.versions[i].length;
    }

    let aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, iv);
    let encryptedBytes = aesCbc.encrypt(plaintext);
    let encryptedVault = window.btoa(String.fromCharCode(...new Uint8Array(encryptedBytes)));

    await put(servicePassword, {
        encryptedVault: encryptedVault,
    });
}

async function deleteVault(masterKey) {
    let servicePassword = computeServicePassword(masterKey);
    await del(servicePassword);
}

class VaultNumberComponent extends React.Component {
    constructor(props) {
        super(props);
        this.next = props.next;
        this.onChange = props.onChange;
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.input = new Array(6);
    }

    handleKeyUp(index) {
        let that = this;
        return (event) => {
            if (event.key === 'Backspace' && index > 0) {
                that.input[index - 1].focus();
            }
            if (that.input[index].value.length === 1 && index < 5) {
                that.input[index + 1].focus();
            }
        }
    }

    handleChange(index) {
        let that = this;
        return (event) => {
            if (event.target.value.match(/[0-9]?/)) {
                let newValue = [...that.props.value];
                newValue[index] = event.target.value.substr(0, 1);
                if (that.onChange !== undefined) {
                    that.onChange(newValue);
                }
            }
        }
    }

    handleFocus(event) {
        event.target.select();
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-3">
                    <label className="vault-number-title d-none d-md-block d-lg-none">Vault Nr.</label>
                    <label className="vault-number-title d-md-none d-lg-block">Vault Number</label>
                </div>
                <div className="col-md-9">
                    <div className="form-group">
                        {this.renderInput(0, 'first')}
                        {this.renderInput(1)}
                        {this.renderInput(2)}
                        {this.renderInput(3)}
                        {this.renderInput(4)}
                        {this.renderInput(5, 'last')}
                    </div>
                </div>
            </div>
        )
    }

    renderInput(index, extraClass = '') {
        let disabled = this.props.disabled || false;
        return <input type="number" className={'vault-number-digit form-control ' + extraClass}
                      maxLength="1"
                      value={this.props.value[index]}
                      onKeyUp={this.handleKeyUp(index)}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange(index)}
                      ref={(elm) => {
                          this.input[index] = elm
                      }}
                      disabled={disabled}
                      onKeyDown={(e) => {
                          if (e.which === 38 || e.which === 40) e.preventDefault()
                      }}/>;
    }

    componentDidMount() {
        for (let i = 0; i < 6; i++) {
            this.input[i].addEventListener('wheel', (e) => {
                e.preventDefault()
            }, {passive: false});
        }
    }
}

class OpenVaultComponent extends React.Component {
    constructor(props) {
        super(props);
        this.onOpen = props.onOpen;
        this.state = {vaultNumber: ['', '', '', '', '', ''], password: ''};
        this.enabled = this.enabled.bind(this);
        this.handleVaultNumberChange = this.handleVaultNumberChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.clickOpenVault = this.clickOpenVault.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    enabled() {
        let validVaultNumber = true;
        for (let i = 0; i < 6; i++) {
            if (!this.state.vaultNumber[i].match(/[0-9]/)) {
                validVaultNumber = false;
            }
        }
        return validVaultNumber && this.state.password.length > 8
    }

    handleVaultNumberChange(vaultNumber) {
        this.setState({vaultNumber: vaultNumber})
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value})
    }

    clickOpenVault(event) {
        this.onOpen(this.state.vaultNumber, this.state.password);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.clickOpenVault(event);
        }
    }

    render() {
        return (
            <div className="col-md-6 mb40">
                <h2>Open Existing Vault</h2>

                <VaultNumberComponent next={() => {
                    return this.passwordInput.focus()
                }}
                                      value={this.state.vaultNumber}
                                      onChange={this.handleVaultNumberChange}/>

                <div className="form-group">
                    <input type="password" className="form-control" placeholder="Password"
                           value={this.state.password}
                           onChange={this.handleChangePassword}
                           onKeyPress={this.handleKeyPress}
                           ref={(input) => {
                               this.passwordInput = input;
                           }}/>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary mr-1"
                            onClick={this.clickOpenVault}
                            disabled={!this.enabled()}><span className="oi oi-lock-unlocked"/> Open Vault
                    </button>
                    {this.props.thinking && <img src="/images/spinner.gif" alt="Please Wait" height="38" width="38"/>}
                </div>
                {this.props.error !== '' && <div className="alert alert-danger" role="alert">{this.props.error}</div>}
            </div>
        )
    }
}

class CreateVaultComponent extends React.Component {
    constructor(props) {
        super(props);
        this.onCreate = props.onCreate;
        this.state = {
            vaultNumber: ['', '', '', '', '', ''],
            password: '',
            passwordRepeat: '',
            passwordMethod: 'choose',
            vaultMethod: 'choose',
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordRepeat = this.handleChangePasswordRepeat.bind(this);
        this.enabled = this.enabled.bind(this);
        this.clickCreateVault = this.clickCreateVault.bind(this);
        this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this);
        this.handleVaultNumberChange = this.handleVaultNumberChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.passwordMethodChange = this.passwordMethodChange.bind(this);
        this.vaultMethodChange = this.vaultMethodChange.bind(this);
    }

    enabled() {
        let validVaultNumber = true;
        for (let i = 0; i < 6; i++) {
            if (!this.state.vaultNumber[i].match(/[0-9]/)) {
                validVaultNumber = false;
            }
        }
        return validVaultNumber > 0
            && this.state.password.length > 8
            && this.state.password === this.state.passwordRepeat;
    }

    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    handlePasswordKeyPress(event) {
        if (event.key === 'Enter') {
            this.passwordRepeatInput.focus();
        }
    }

    handleChangePasswordRepeat(event) {
        this.setState({passwordRepeat: event.target.value});
    }

    handleVaultNumberChange(vaultNumber) {
        this.setState({vaultNumber: vaultNumber});
    }

    clickCreateVault(event) {
        this.onCreate(this.state.vaultNumber, this.state.password);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.clickCreateVault(event);
        }
    }

    passwordMethodChange(event) {
        let method = event.target.value;
        let password;
        if (method === 'random') {
            password = generateRandomPassword();
        } else {
            password = '';
        }
        this.setState({passwordMethod: method, password: password, passwordRepeat: ''});
    }

    vaultMethodChange(event) {
        let method = event.target.value;
        let vaultNumber;
        if (method === 'random') {
            let values = new Uint32Array(6);
            window.crypto.getRandomValues(values);
            vaultNumber = [];
            for (let i = 0; i < 6; i++) {
                vaultNumber.push('' + (values[i] % 10));
            }
        } else {
            vaultNumber = ['', '', '', '', '', ''];
        }
        this.setState({vaultMethod: method, vaultNumber: vaultNumber});
    }

    render() {
        return (<div className="col-md-6 mb40">
            <h2>Create New Vault</h2>

            <VaultNumberComponent next={() => {
                return this.passwordInput.focus()
            }}
                                  value={this.state.vaultNumber}
                                  disabled={this.state.vaultMethod === 'random'}
                                  onChange={this.handleVaultNumberChange}/>

            <div className="form-group">
                <input type={this.state.passwordMethod === 'choose' ? 'password' : 'text'}
                       disabled={this.state.passwordMethod === 'choose' ? false : true}
                       className="form-control" placeholder="Password (minimum 9 characters)"
                       value={this.state.password}
                       onChange={this.handleChangePassword}
                       onKeyPress={this.handlePasswordKeyPress}
                       ref={(input) => {
                           this.passwordInput = input;
                       }}/>
            </div>

            <div className="form-group">
                <input type={this.state.passwordMethod === 'choose' ? 'password' : 'text'}
                       className="form-control" placeholder="Re-enter password"
                       value={this.state.passwordRepeat}
                       onChange={this.handleChangePasswordRepeat}
                       onKeyPress={this.handleKeyPress}
                       ref={(input) => {
                           this.passwordRepeatInput = input;
                       }}/>
            </div>

            <div className="form-group">
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                        className={"btn btn-outline-secondary" + (this.state.vaultMethod === 'choose' ? ' active' : '')}>
                        <input type="radio" autoComplete="off" value="choose"
                               checked={this.state.vaultMethod === 'choose'}
                               onChange={this.vaultMethodChange}/>Choose Vault Number
                    </label>
                    <label
                        className={"btn btn-outline-secondary" + (this.state.vaultMethod === 'random' ? ' active' : '')}>
                        <input type="radio" autoComplete="off" value="random"
                               checked={this.state.vaultMethod === 'random'}
                               onChange={this.vaultMethodChange}/>Random Vault Number</label>
                </div>
            </div>

            <div className="form-group">
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                        className={"btn btn-outline-secondary" + (this.state.passwordMethod === 'choose' ? ' active' : '')}>
                        <input type="radio" autoComplete="off" value="choose"
                               checked={this.state.passwordMethod === 'choose'}
                               onChange={this.passwordMethodChange}/>Choose Password
                    </label>
                    <label
                        className={"btn btn-outline-secondary" + (this.state.passwordMethod === 'random' ? ' active' : '')}>
                        <input type="radio" autoComplete="off" value="random"
                               checked={this.state.passwordMethod === 'random'}
                               onChange={this.passwordMethodChange}/>Random Password</label>
                </div>
            </div>

            <div className="form-group">
                <button className="btn btn-primary mr-1 mb-1"
                        onClick={this.clickCreateVault}
                        disabled={!this.enabled()}><span className="oi oi-bolt"/> Create Vault
                </button>
                {this.props.thinking && <img src="/images/spinner.gif" alt="Please Wait" height="38" width="38"/>}
            </div>
            {this.props.error !== '' && <div className="alert alert-danger" role="alert">{this.props.error}</div>}
        </div>)
    }
}

class DisplayVaultComponent extends React.Component {
    constructor(props) {
        super(props);
        this.clickPreDelete = this.clickPreDelete.bind(this);
        this.clickDeleteCancel = this.clickDeleteCancel.bind(this);
        this.deleteConfirmTextChange = this.deleteConfirmTextChange.bind(this);
        this.state = {'preDeletePressed': false, deleteConfirmText: '', 'deleteConfirmEnabled': false};
    }

    static propTypes = {
        vaultNumber: PropTypes.array,
        vault: PropTypes.string,
    };

    clickPreDelete() {
        this.setState({preDeletePressed: true});
    }

    clickDeleteCancel() {
        this.setState({preDeletePressed: false, deleteConfirmText: '', deleteConfirmEnabled: false});
    }

    deleteConfirmTextChange(event) {
        this.setState({
            deleteConfirmText: event.target.value,
            deleteConfirmEnabled: event.target.value === 'DELETE'
        });
    }

    render() {
        return (
            <div className="col-md-6 mb40">
                <h2>Your Vault</h2>
                <VaultNumberComponent value={this.props.vaultNumber} disabled={true} readOnly={true}/>

                <div className="form-group">
                    <textarea rows="6" cols="40" className="form-control" value={this.props.vault}
                              onChange={this.props.onChange}/>
                </div>

                <div className="form-group">
                    <button type="button" className="btn btn-light mr-2" onClick={this.props.clickReset}><span
                        className="oi oi-chevron-left"/> Back
                    </button>
                    <button type="button" className="btn btn-success mr-2" onClick={this.props.clickSaveVault}><span
                        className="oi oi-check"/> Save Vault
                    </button>
                    <button type="button" className="btn btn-danger" onClick={this.clickPreDelete}><span
                        className="oi oi-trash"/> Delete Vault
                    </button>
                </div>
                {this.props.result !== '' &&
                <div className="alert alert-success" role="alert">{this.props.result}</div>}

                <div hidden={!this.state.preDeletePressed}>
                    <div className="form-group">
                        <input type="input" className="form-control" placeholder="Type 'DELETE' to delete this vault"
                               value={this.state.deleteConfirmText}
                               onChange={this.deleteConfirmTextChange}/>
                    </div>
                    <div className="form-group">
                        <button type="button" className="btn btn-warning mr-2" onClick={this.props.clickDeleteVault}
                                disabled={!this.state.deleteConfirmEnabled}><span
                            className="oi oi-check"/> Confirm
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={this.clickDeleteCancel}><span
                            className="oi oi-x"/> Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}


class VaultUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            masterKey: null,
            vaultNumber: ['', '', '', '', '', ''],
            password: '',
            vault: '',
            openError: '',
            openThinking: false,
            createError: '',
            createThinking: false,
            actionResult: '',
            disabled: true
        };
        this.masterKey = null;
        this.handleChangeVaultNumber = this.handleChangeVaultNumber.bind(this);
        this.handleChangeVault = this.handleChangeVault.bind(this);
        this.clickOpenVault = this.clickOpenVault.bind(this);
        this.clickSaveVault = this.clickSaveVault.bind(this);
        this.clickDeleteVault = this.clickDeleteVault.bind(this);
        this.clickCreateVault = this.clickCreateVault.bind(this);
        this.clickReset = this.clickReset.bind(this);
    }

    handleChangeVaultNumber(vaultNumber) {
        this.setState({vaultNumber: vaultNumber});
    }

    handleChangeVault(event) {
        this.setState({vault: event.target.value});
    }


    clickOpenVault(vaultNumber, password) {
        this.setState({openThinking: true}, async () => {
            try {
                let start = new Date();
                let masterKey = await computeKey(vaultNumber, password);
                let end = new Date();
                console.log('Argon2 took:', end - start, 'ms');
                this.vault = await decrypt(masterKey);
                console.log(this.vault);
                this.setState({
                    masterKey: masterKey,
                    vaultNumber: vaultNumber,
                    vault: this.vault.versions.length > 0 ? this.vault.versions[this.vault.versions.length - 1] : '',
                    openError: '',
                    createError: '',
                    openThinking: false
                });
            } catch (e) {
                this.setState({openError: e.message, openThinking: false});
            }
        });
    }

    async clickCreateVault(vaultNumber, password) {
        this.setState({createThinking: true}, async () => {
            try {
                let masterKey = await computeKey(vaultNumber, password);
                this.vault = {versions: []};
                await encrypt(masterKey, this.vault);
                this.setState({
                    masterKey: masterKey,
                    vaultNumber: vaultNumber,
                    vault: '',
                    createError: '',
                    openError: '',
                    createThinking: false,
                });
            } catch (e) {
                this.setState({createError: e.message, createThinking: false});
            }
        });
    }

    async clickSaveVault(event) {
        this.vault.versions.push(this.state.vault);
        await encrypt(this.state.masterKey, this.vault);
        this.setState({actionResult: 'Saved'});
        setTimeout(() => {
            this.setState({actionResult: ''});
        }, 2000);
    }

    async clickDeleteVault(event) {
        await deleteVault(this.state.masterKey);
        this.setState({actionResult: '', vault: '', masterKey: null, vaultNumber: ['', '', '', '', '', '']});
    }

    clickReset(event) {
        this.setState({actionResult: '', masterKey: null, vault: '', vaultNumber: ['', '', '', '', '', '']});
    }

    render() {
        if (!this.state.masterKey) {
            return (
                <div className="row">
                    <OpenVaultComponent onOpen={this.clickOpenVault}
                                        error={this.state.openError}
                                        thinking={this.state.openThinking}/>
                    <CreateVaultComponent onCreate={this.clickCreateVault}
                                          error={this.state.createError}
                                          thinking={this.state.createThinking}/>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <DisplayVaultComponent vaultNumber={this.state.vaultNumber}
                                           vault={this.state.vault}
                                           onChange={this.handleChangeVault}
                                           clickSaveVault={this.clickSaveVault}
                                           clickDeleteVault={this.clickDeleteVault}
                                           clickReset={this.clickReset}
                                           result={this.state.actionResult}/>
                </div>
            );
        }
    }
}

function App() {
    return (
        <div className="container">
            <h1><span className="title-secu">Secu</span><span className="title-box">Box</span></h1>
            <p className="mb40">SecuBox is a browser based encryption service that allows you to create anonymous
                encrypted vaults to
                store sensitive information. When you create a new vault the vault number and the password are combined
                to create a master key using the Argon2 password hashing function. This master key is then used to
                generate an encryption key and a storage key. The contents of your vault is encrypted with the
                encryption key and stored in a secure service hosted in AWS using the storage key.</p>
            <VaultUI/>

            <footer>
                <div className="footer-copyright text-center py-3">
                    &copy; 2019 Copyright: <a href="https://jeffreybolle.com/">Jeffrey Bolle</a>
                </div>
            </footer>
        </div>);
}

export default App;
